<template>
  <div class="w-full text-center" v-if="loadingStatuses.getRosterDetails">
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
  <div v-else class="space-y-4 staff_replace">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card v-if="filter.officer">
      <div class="space-y-5">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
          <asom-form-field
            label="Officer"
            required
            :state="inputStates('filter.officer')"
            error="Select an officer"
          >
            <asom-input-select
              v-model="filter.officer"
              disabled
              :state="inputStates('filter.officer')"
              :options="[]"
            />
          </asom-form-field>
          <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
            <asom-form-field
              label="Line"
              required
              :state="inputStates('filter.line')"
              error="Select a line"
            >
              <asom-input-select
                disabled
                v-model="filter.line"
                :options="lineOptions"
                :state="inputStates('filter.line')"
              />
            </asom-form-field>
            <asom-form-field
              label="Station"
              required
              :state="inputStates('filter.station')"
              error="Select a station"
            >
              <asom-input-select
                disabled
                v-model="filter.station"
                :options="stationOptions"
                :state="inputStates('filter.station')"
              />
            </asom-form-field>
          </div>
          <asom-form-field label="DWS" :state="inputStates('filter.dws')">
            <asom-input-select
              v-model="filter.dws"
              :disabled="true"
              :options="dwsOptions"
              track-by="dws"
              :state="inputStates('filter.dws')"
            />
          </asom-form-field>
          <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
            <asom-form-field
              label="Shift Starting Time"
              required
              :state="inputStates('filter.dateTimeFrom')"
              error="Please provide start date and time"
            >
              <asom-input-date-time
                disabled
                v-model="filter.dateTimeFrom"
                :state="inputStates('filter.dateTimeFrom')"
              />
            </asom-form-field>
            <asom-form-field
              label="Shift Ending Time"
              required
              :state="inputStates('filter.dateTimeTo')"
              error="Please provide end date and time"
            >
              <asom-input-date-time
                disabled
                v-model="filter.dateTimeTo"
                :state="inputStates('filter.dateTimeTo')"
              />
            </asom-form-field>
          </div>
        </div>
      </div>
    </asom-card>
    <template v-if="!error && !assignResult">
      <div class="w-full text-center" v-if="loadingStatuses.searchReplacements">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <asom-card v-else>
        <div class="space-y-4">
          <asom-alert title="Select only 1 officer">
            <p class="text-sm text-blue-700 pt-2">
              Note: consecutive working day includes the day selected for
              replacement.
            </p>
          </asom-alert>
          <officer-selection :officers="searchData" v-model="assignedOfficer" />
        </div>
        <template #footer>
          <asom-button
            text="Assign"
            @click="assignOTAsync"
            :loading="loadingStatuses.assignNewRoster"
            :disabled="isAssignBtnDisabled"
          />
        </template>
      </asom-card>
    </template>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import { mapGetters } from "vuex";
import moment from "moment";
import get from "lodash.get";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  getRosterGap,
  getReplacementSuggestions,
  assignOT,
  assignSpare,
  assignExtendShift,
  // getLineDws,
} from "@/services/manpower.service";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import { ASSIGN_OT, ASSIGN_SPARE, COVERING_SHIFT_SAME_STATION } from "../../../constants/apis";
import OfficerSelection from "./OfficerSelection.vue";
import AsomAlert from "../../../components/alert/AsomAlert.vue";

export default {
  components: {
    OfficerSelection,
    AsomAlert,
  },
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      filter: {
        officer: null,
        station: null,
        line: null,
        dws: null,
        dateTimeFrom: null,
        dateTimeTo: null,
      },
      rosterInfo: {
        planDate: null,
        unavailability: false,
      },
      onExtensionNeedBackward: false,
      onExtensionNeedForward: false,
      searchData: [],
      assignedOfficer: null,
      dwsOptions: [],
      isLoadingRosterDetails: false,
      loadingStatuses: {
        getRosterDetails: false,
        searchReplacements: false,
        assignNewRoster: false,
      },
      extensionShiftTime: {
        priorShiftEndDate: null,
        afterShiftStartDate: null,
      },
      showModal: false,
      error: null,
      assignResult: null,
      isOTAssignmentSucceeded: false,
    };
  },
  validations() {
    return {
      filter: {
        officer: { required },
        station: { required },
        line: { required },
        dws: { required },
        dateTimeFrom: { required },
        dateTimeTo: { required },
      },
    };
  },
  mounted() {
    this.v$ && this.v$.$reset();
    this.loadData();
  },
  watch: {
    "filter.dws": function (newValue) {
      if (newValue && newValue.shiftStart && newValue.shiftEnd) {
        const _planStart =
          this.rosterInfo.planDate.split(" ")[0] + " " + newValue.shiftStart;
        const _planEnd =
          this.rosterInfo.planDate.split(" ")[0] + " " + newValue.shiftEnd;
        this.filter.dateTimeFrom = moment(
          _planStart,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();
        this.filter.dateTimeTo = moment(
          _planEnd,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();
      }
    },
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      stationOptions: "smrtResource/stationOptions",
    }),
    needBothExtensions() {
      return this.onExtensionNeedForward && this.onExtensionNeedBackward;
    },
    assignValidationError() {
      let error = null;
      if (!this.assignedOfficer)
        error = "Select only 1 officer for replacement.";
      return error;
    },
    apiForAssignment() {
      if (this.assignValidationError) return null;
      const dws = get(this.assignedOfficer, "dws", "");
      switch (dws.toLowerCase()) {
        case "spare":
          return ASSIGN_SPARE;
        case "off":
        case "rest":
          return ASSIGN_OT;
        default:
          return COVERING_SHIFT_SAME_STATION;
      }
    },
    dataForAssignment() {
      switch (this.apiForAssignment) {
        case ASSIGN_OT:
          return {
            officerId: this.assignedOfficer.officerId,
            stationId: get(this.filter.station, "value", null),
            lineId: get(this.filter.line, "value", null),
            dws: this.filter.dws.dws,
            planDate: parseDateTime(this.rosterInfo.planDate),
            shiftStartTime: parseDateTime(this.filter.dateTimeFrom),
            shiftEndTime: parseDateTime(this.filter.dateTimeTo),
            replacingRosterId: this.$route.params.rosterId,
          };
        case ASSIGN_SPARE:
          return {
            officerId: this.assignedOfficer.officerId,
            stationId: get(this.filter.station, "value", null),
            lineId: get(this.filter.line, "value", null),
            dws: this.filter.dws.dws,
            planDate: parseDateTime(this.rosterInfo.planDate),
            replacingRosterId: this.$route.params.rosterId,
          };
        case COVERING_SHIFT_SAME_STATION:
          return {
            officerId: this.assignedOfficer.officerId,
            planDate: parseDateTime(this.rosterInfo.planDate),
            coveringRosterId: this.$route.params.rosterId,
          }
        default:
          return {};
      }
    },
    isAssignBtnDisabled() {
      return (
        this.loadingStatuses.assignNewRoster || !!this.assignValidationError
      );
    },
  },
  methods: {
    getDwsLabel(dws, shiftStart, shiftEnd) {
      return (
        dws +
        (shiftStart && shiftEnd
          ? " (" + shiftStart + " - " + shiftEnd + ")"
          : "")
      );
    },
    setRosterDetails({
      roster: {
        officerId,
        officer,
        station,
        line,
        planDate,
        planStart,
        planEnd,
        dws,
        dwsText,
        unavailability = false,
      },
      coverageRequirementStart,
      coverageRequirementEnd,
      onExtensionNeedBackward = false,
      onExtensionNeedForward = false,
    }) {
      if (officerId && officer) {
        this.filter.officer = {
          label: officer,
          value: officerId,
        };
      }
      this.filter.station = {
        label: get(station, "stationName", null),
        value: get(station, "stationId", null),
      };
      this.filter.line = {
        label: get(line, "lineName", null),
        value: get(line, "lineId", null),
      };
      if (coverageRequirementStart)
        this.filter.dateTimeFrom = moment(
          coverageRequirementStart,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();
      if (coverageRequirementEnd)
        this.filter.dateTimeTo = moment(
          coverageRequirementEnd,
          "DD/MM/YYYY HH:mm:ss"
        ).toDate();
      this.filter.dws = {
        label: this.getDwsLabel(
          dws,
          planStart ? planStart.split(" ")[1] : null,
          planEnd ? planEnd.split(" ")[1] : null
        ),
        dwsText: dwsText,
        dws: dws,
      };
      this.rosterInfo.planDate = planDate;
      this.rosterInfo.unavailability = unavailability;
      this.onExtensionNeedBackward = onExtensionNeedBackward;
      this.onExtensionNeedForward = onExtensionNeedForward;
    },
    async loadData() {
      if (this.$route.params.rosterId) {
        this.loadingStatuses.getRosterDetails = true;
        const result = await getRosterGap(this.$route.params.rosterId);
        if (result.success) {
          this.setRosterDetails(get(result, "payload", {}));
          // this.$nextTick(() => this.getDwsOptions())
          this.$nextTick(() => this.search());
        } else {
          this.error = "Failure to get roster details";
          this.$scrollTop();
        }
        this.loadingStatuses.getRosterDetails = false;
      } else {
        this.error = "No Roster Found.";
        this.$scrollTop();
      }
    },
    async search() {
      if (this.loadingStatuses.searchReplacements) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.loadingStatuses.searchReplacements = true;
        this.searchData = [];
        const result = await getReplacementSuggestions({
          officerId: this.filter.officer.value,
          lineId: get(this.filter.line, "value", null),
          stationId: get(this.filter.station, "value", null),
          dateTimeFrom: parseDateTime(this.filter.dateTimeFrom),
          dateTimeTo: parseDateTime(this.filter.dateTimeTo),
        });
        if (result.success && Array.isArray(get(result, "payload.officers"))) {
          this.extensionShiftTime.priorShiftEndDate = this.filter.dateTimeFrom;
          this.extensionShiftTime.afterShiftStartDate =
            this.filter.dateTimeFrom;
          this.searchData = get(result, "payload.officers", []).map((item) => ({
            ...item,
            id: nanoid(),
          }));
        }
        this.loadingStatuses.searchReplacements = false;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async assignOTAsync() {
      if (this.assignValidationError) {
        this.assignResult = this.assignValidationError;
        this.showModal = true;
        return;
      } else {
        let api = null;
        switch (this.apiForAssignment) {
          case ASSIGN_OT:
            api = assignOT;
            break;
          case ASSIGN_SPARE:
            api = assignSpare;
            break;
          case COVERING_SHIFT_SAME_STATION:
            api = assignExtendShift;
            break;
          default:
            break;
        }
        if (api) {
          this.loadingStatuses.assignNewRoster = true;
          this.assignResult = null;
          const result = await api(this.dataForAssignment);
          this.loadingStatuses.assignNewRoster = false;
          if (result && result.payload && result.payload.success) {
            this.isOTAssignmentSucceeded = true;
            this.assignResult = "Successfully assign new officer.";
            this.$router.push({ name: "Supervisor Roster" });
          } else {
            this.assignResult = result.payload;
            this.error = result.payload;
            this.$scrollTop();
          }
          this.showModal = true;
        } else {
          this.assignResult = "Cannot find server.";
          this.showModal = true;
        }
      }
    },
    onCloseModal() {
      this.showModal = false;
      if (this.isOTAssignmentSucceeded) {
        this.$router.push({
          name: "Supervisor Roster",
          query: {
            lineId: get(this.filter.line, "value", null),
            stationId: get(this.filter.station, "value", null),
          },
        });
      }
    },
  },
};
</script>

<style scope>
.staff_replace .AsomCard__Body {
  @apply sm:rounded-tr-md sm:rounded-tl-md;
}
</style>
