<template>
  <asom-client-table
    :data="officers"
    :columns="[
      'check',
      'officer',
      'otHours',
      'daysInRow',
      'station',
      'group',
      'zone',
      'dws',
      'distance',
    ]"
    :sortableColumns="[
      'officer',
      'station',
      'otHours',
      'workType',
    ]"
    :filterable="false"
    :pagination="false"
    tableClasses="tbl_officerSelection"
  >
    <template v-slot:header_check><span></span></template>
    <template v-slot:header_otHours>OT Served (Hours)</template>
    <template v-slot:header_distance>
      <asom-tooltip tooltip-text="(# of stations)">
        <template #toggleText>
          <span>Distance</span>
        </template>
      </asom-tooltip>
    </template>
    <template v-slot:header_stationSeq>Station Sequence</template>
    <template v-slot:header_daysInRow>Working Days In Row</template>
    <template v-slot:header_isOtVolunteered>OT Volunteered</template>
    <template v-slot:check="scopedSlots">
      <input type="radio" :value="scopedSlots.rowData" v-model="selectedItem" />
    </template>
    <template v-slot:isBlockedLeave="scopedSlots">{{
      scopedSlots.data ? "Yes" : "No"
    }}</template>
    <template v-slot:officer="scopedSlots">
      <div class="space-x-2 flex flex-row items-center">
        <span class="flex-1">{{ scopedSlots.rowData.officer }}</span>
        <asom-tooltip
          v-if="scopedSlots.rowData.isBlockedLeave"
          tooltip-text="Indicated Leave"
        >
          <template #toggleText>
            <asom-badge variant="error">B</asom-badge>
          </template>
        </asom-tooltip>
        <asom-tooltip v-if="scopedSlots.rowData.breaksMaxOtHoursMonth"
          tooltip-text="Breaking maximum work hours a month (MOM rules)"
        >
          <template #toggleText>
             <asom-icon class="text-red-400" icon="info" size="sm" />
          </template>
        </asom-tooltip>
        <div>
          <div v-if="scopedSlots.rowData.isOtVolunteered" class="badgeBlock">
            <div :class="['badgeText', 'bgGreen']">
              OT Volunteered
            </div>
          </div>
          <div v-if="scopedSlots.rowData.isBlockedLeave" class="badgeBlock">
            <div :class="['badgeText', 'bgRed']">
              Requested Leave
            </div>
          </div>
        </div>
      </div>
    </template>
  </asom-client-table>
</template>

<script>
export default {
  props: {
    officers: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      selectedItem: null,
    }
  },
  emits: ["update:modelValue"],
  mounted() {
    this.selectedItem = this.modelValue;
  },
  watch: {
    officers(newValue) {
      if (newValue) {
        this.$emit("update:modelValue", []);
      }
    },
    selectedItem(newValue, oldValue) {
      if (newValue !== oldValue)
        this.$emit('update:modelValue', newValue);
    }
  },
  methods: {
    onCheck(rowData) {
      const modelIds = this.modelValue.map((r) => r.id);
      const containsOption = modelIds.includes(rowData.id);
      if (containsOption) {
        this.$emit(
          "update:modelValue",
          
          this.modelValue.filter((r) => r.id !== rowData.id)
        );
      } else {
        this.$emit(
          "update:modelValue",
          this.officers.filter(
            (r) => r.id === rowData.id || modelIds.includes(r.id)
          )
        );
      }
    },
  },
};
</script>

<style lang="scss">
.tbl_officerSelection {
  tbody td:nth-child(2) {
    @apply font-semibold text-gray-900;
  }
  tbody td{
    white-space: nowrap;
  }
}
.badgeBlock {
  @apply flex p-1;
}
.badgeText {
  @apply py-1 px-3 text-xs font-semibold bg-gray-400 text-white tracking-wider rounded-l-md rounded-r-md;
}
.bgGreen {
  @apply bg-green-400;
}
.bgRed {
  @apply bg-red-400;
}
</style>
